@import '../../scss/mixins.scss';

.container {
  display: flex;
  height: 493px;
  background: #ffffff;
  flex-direction: column;
  margin-top: 200px;

  @include tablet-up {
    height: 688px;
  }
}

.topContent {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  padding: 0 24px;

  @include tablet-up {
    padding: 0 10.33vw;
  }

  @include desktop-up {
    padding: 0 17.66vw;
    flex-direction: row;
    justify-content: space-between;
  }
}

.topContentText {
  padding-bottom: 56px;
  flex-direction: column;

  @include desktop-up {
    padding-bottom: unset;
    align-self: center;
  }
}

.imageContainer {
  display: flex;
  flex-direction: column;

  @include desktop-up {
    align-self: center;
  }
}

.topPrimaryText {
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  color: #000000;
  padding-bottom: 24px;

  @include tablet-up {
    font-size: 32px;
    line-height: 48px;
  }
}

.topSecondaryText {
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #000000;

  @include tablet-up {
    font-size: 16px;
    line-height: 24px;
  }
}

.appstoreBadge {
  display: inline-block;
  overflow: hidden;
  background: url('https://linkmaker.itunes.apple.com/en-us/badge-lrg.svg?releaseDate=2019-02-15&kind=iossoftware&bubble=ios_apps')
    no-repeat;
  width: 135px;
  height: 40px;
  margin-bottom: 8px;

  @include desktop-up {
    align-self: center;
  }
}

.googleplayBadge {
  display: inline-block;
  width: 156px;
  margin: -10px;

  @include desktop-up {
    align-self: center;
  }
}

.bottomContent {
  display: flex;
  flex-direction: column;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding: 32px 24px;

  @include tablet-up {
    padding: 88px 10.33vw;
  }

  @include desktop-up {
    padding: 100px 17.66vw;
    flex-direction: row;
    justify-content: space-between;
  }
}

.bottomPrimaryText {
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  padding-bottom: 12px;

  @include tablet-up {
    font-size: 16px;
    line-height: 20px;
  }
}

.bottomSecondaryText {
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #000000;
  opacity: 0.4;
}

.link {
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;

  background-image: linear-gradient(#ff4c00, #ff0000);
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;

  text-decoration: none;
  border-bottom: 1px solid #ff2600;

  @include tablet-up {
    font-size: 16px;
    line-height: 20px;
  }
}
