@import '../../scss/mixins.scss';

.container {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.container {
  justify-content: flex-start;
  padding: 72px 24px 0;

  @include tablet-up {
    /* For tablets: */
    padding: 120px 8.33vw 0;
  }

  @include desktop-up {
    /* For desktops */
    padding: 0;
  }
}

.content {
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 100vh;

  @include desktop-up {
    flex-direction: row-reverse;
  }
}
