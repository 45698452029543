@import '../../scss/mixins.scss';

.container {
  padding: 0 16px;

  &:hover {
    background: #eeeeef;
    border-radius: 4px;
  }
}

.listItem {
  display: flex;
  flex-direction: row;
  padding: 12px 0;
  cursor: pointer;
  border-bottom: 1px solid rgb(238, 238, 238);
}

.listItemContent {
  flex: 1;
}

.listItemTopRow {
  display: flex;
  flex-direction: row;
}

.listItemBottomRow {
  display: flex;
  flex-direction: row;
  margin-top: 3px;
}

.listItemNumber {
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #000006;
  margin-bottom: 3px;
  text-overflow: ellipsis;
  width: 32px;

  @include desktop-up {
    font-size: 17.2669px;
    line-height: 21px;
  }
}

.listItemTitle {
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #000006;
  text-overflow: ellipsis;

  @include desktop-up {
    font-size: 17.2669px;
    line-height: 21px;
  }
}

.listItemSubtitle {
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.3);
  text-overflow: ellipsis;

  @include desktop-up {
    font-size: 12.9502px;
    line-height: 15px;
  }
}
