@import-normalize;
@import './scss/fonts.scss';
@import './scss/mixins.scss';

html {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  background-color: #fcfcfc;
  margin: 0;
}

body,
#root {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 0;
  cursor: pointer;

  @include tablet-up {
    cursor: inherit;
  }
}
