@font-face {
  font-family: 'Helvetica Neue';
  src: url('./assets/fonts/HelveticaNeue-BoldItalic.woff2') format('woff2'),
    url('./assets/fonts/HelveticaNeue-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('./assets/fonts/HelveticaNeue-Light.woff2') format('woff2'),
    url('./assets/fonts/HelveticaNeue-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('./assets/fonts/HelveticaNeue-Italic.woff2') format('woff2'),
    url('./assets/fonts/HelveticaNeue-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('./assets/fonts/HelveticaNeue.woff2') format('woff2'),
    url('./assets/fonts/HelveticaNeue.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('./assets/fonts/HelveticaNeue-MediumItalic.woff2') format('woff2'),
    url('./assets/fonts/HelveticaNeue-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('./assets/fonts/HelveticaNeue-CondensedBlack.woff2') format('woff2'),
    url('./assets/fonts/HelveticaNeue-CondensedBlack.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('./assets/fonts/HelveticaNeue-CondensedBold.woff2') format('woff2'),
    url('./assets/fonts/HelveticaNeue-CondensedBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('./assets/fonts/HelveticaNeue-Thin.woff2') format('woff2'),
    url('./assets/fonts/HelveticaNeue-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('./assets/fonts/HelveticaNeue-LightItalic.woff2') format('woff2'),
    url('./assets/fonts/HelveticaNeue-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('./assets/fonts/HelveticaNeue-ThinItalic.woff2') format('woff2'),
    url('./assets/fonts/HelveticaNeue-ThinItalic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('./assets/fonts/HelveticaNeue-UltraLight.woff2') format('woff2'),
    url('./assets/fonts/HelveticaNeue-UltraLight.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('./assets/fonts/HelveticaNeue-Medium.woff2') format('woff2'),
    url('./assets/fonts/HelveticaNeue-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('./assets/fonts/HelveticaNeue-Bold.woff2') format('woff2'),
    url('./assets/fonts/HelveticaNeue-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('./assets/fonts/HelveticaNeue-UltraLightItalic.woff2')
      format('woff2'),
    url('./assets/fonts/HelveticaNeue-UltraLightItalic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
}
