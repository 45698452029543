@import '../../scss/mixins.scss';

.container {
  display: flex;
  flex-direction: row;
  align-items: center;

  @include desktop-up {
    /* For desktops */
    padding: 40px 32px 0;
  }
}

.linkContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
}

.logo {
  height: 24px;
  width: auto;
}

.text {
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: bold;
  font-size: 15.162px;
  line-height: 18px;
  text-align: center;
  color: #ff2600;
  margin-left: 16px;

  @include tablet-up {
    font-size: 17.3714px;
    line-height: 21px;
  }
}
