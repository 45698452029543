$breakpoint-tablet: 768px;
$breakpoint-desktop: 1440px;

@mixin landscape {
  @media screen and (orientation: landscape) {
    @content;
  }
}

@mixin tablet-up {
  @media screen and (min-width: $breakpoint-tablet) {
    @content;
  }
}

@mixin landscape-tablet-up {
  @media screen and (orientation: landscape) and (min-height: $breakpoint-tablet) {
    @content;
  }
}

@mixin desktop-up {
  @media screen and (min-width: $breakpoint-desktop) {
    @content;
  }
}
