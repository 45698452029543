@import '../../scss/mixins.scss';

.overylay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  background: #000000;
  opacity: 0.1;
  will-change: opacity;
  transition: opacity 300ms cubic-bezier(0.15, 0.15, 0, 1);
}

.containerCollapsed,
.container {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background: #ffffff;
  border-radius: 24px 24px 0px 0px;
  will-change: opacity, transform;
  opacity: 1;
  transform: translateY(0);
  transition: transform 300ms cubic-bezier(0.15, 0.15, 0, 1);
  padding: 0 32px 40px;
  z-index: 10;

  @include tablet-up {
    padding: 0 60px 40px;
  }

  @include desktop-up {
    padding: 0 240px 40px;
  }
}

.containerCollapsed {
  opacity: 0;
  transform: translateY(100%);
  transition: transform 300ms cubic-bezier(0.15, 0.15, 0, 1),
    opacity 0ms cubic-bezier(0.15, 0.15, 0, 1) 300ms;
}

.content {
  display: flex;
  flex-direction: column;

  @include tablet-up {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.tab {
  width: 34px;
  height: 2px;
  background: #000000;
  border-radius: 100px;
  margin: 16px auto;
}

.titleContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 40px;
}

.image {
  height: 74px;
  width: 74px;
  border-radius: 100px;
  background: rgba(0, 0, 0, 0.3);
  object-fit: cover;

  @include tablet-up {
    height: 104px;
    width: 104px;
  }
}

.title {
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: #231f20;
  margin-left: 24px;
  flex: 1;

  @include tablet-up {
    font-size: 24px;
    line-height: 32px;
  }
}

.infoContainer {
  display: flex;
  flex-direction: column;

  @include tablet-up {
    margin: 30px 0 0;
  }

  @include desktop-up {
    margin: 0;
    flex-direction: row;
  }
}

.hoursContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.hoursTitle {
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #231f20;
}

.hoursAddress {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.hours {
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-size: 14px;
  line-height: 19px;
  color: #000006;
  mix-blend-mode: normal;
  opacity: 0.5;
  margin-left: 20px;
}

.address {
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-size: 14px;
  line-height: 19px;
  color: #000006;
  mix-blend-mode: normal;
  opacity: 0.5;
  margin-top: 6px;
}

.actionsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 22px 0 32px;

  @include tablet-up {
    margin: 22px 0 0;
  }

  @include desktop-up {
    margin: 0 0 0 80px;
  }
}

.action {
  padding: 16px;

  @include desktop-up {
    padding: 32px;
  }
}

.actionLink {
  text-decoration: none;
  border: none;
}

.notesContainer {
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  padding-top: 38px;

  @include tablet-up {
    margin-top: 32px;
  }
}

.notesTitle {
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #231f20;
}

.notes {
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #000006;
  mix-blend-mode: normal;
  opacity: 0.5;
  margin-top: 14px;
}

.phoneNumber {
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-size: 14px;
  line-height: 19px;
  color: #000006;
  mix-blend-mode: normal;
  opacity: 0.5;
  margin-top: 6px;
  text-decoration: none;
  border: none;
  display: none;

  @include desktop-up {
    display: block;
  }
}

.actionPhone {
  display: block;

  @include desktop-up {
    display: none;
  }
}
