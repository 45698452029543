@import '../../scss/mixins.scss';

.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-end;
  overflow: hidden;
  margin: 0 -24px;

  @include tablet-up {
    margin: 0;
  }

  @include desktop-up {
    margin: 0;
  }
}

.appstoreBadge {
  display: none;
  overflow: hidden;
  background: url('https://linkmaker.itunes.apple.com/en-us/badge-lrg.svg?releaseDate=2019-02-15&kind=iossoftware&bubble=ios_apps')
    no-repeat;
  width: 135px;
  height: 40px;
  position: absolute;
  will-change: opacity;

  @include tablet-up {
    display: inline-block;
    bottom: 104px;
    right: 80px;

    @include landscape {
      display: none;
    }

    @include landscape-tablet-up {
      display: inline-block;
    }
  }

  @include desktop-up {
    display: inline-block;
    bottom: 80px;
    right: unset;
    left: 32px;
  }
}

.googleplayBadge {
  display: none;
  width: 156px;
  margin: -10px;
  position: absolute;
  will-change: opacity;

  @include tablet-up {
    display: inline-block;
    bottom: 56px;
    right: 80px;

    @include landscape {
      display: none;
    }

    @include landscape-tablet-up {
      display: inline-block;
    }
  }

  @include desktop-up {
    display: inline-block;
    bottom: 32px;
    right: unset;
    left: 32px;
  }
}

.listContainer {
  display: flex;
  align-items: center;
  position: relative;
  will-change: transform;
  width: 100%;
  // Screen height - Peek height - Header height - Top padding height
  margin-top: calc(100vh - 300px - 25px - 72px);
  @include landscape {
    margin-top: 72px;
  }

  @include tablet-up {
    // Screen height - Peek height - Header height - Top padding height
    margin-top: calc(100vh - 404px - 25px - 120px);
    @include landscape {
      margin-top: 100px;
    }
    align-self: flex-start;
    width: 524px;
    margin-left: -50px;
  }

  @include desktop-up {
    // Screen height - Peek height - Header height - Top padding height
    margin-top: calc(100vh - 404px - 25px - 40px);
    margin-left: 16.66vw;
  }
}

.image {
  position: absolute;
  top: 0;
  width: 100%;
  object-fit: contain;
  will-change: opacity, transform;
  transform-origin: center 10%;
}

.list {
  margin-top: 25%;
  width: 100%;
  transform-origin: center top;
  will-change: transform;
  min-height: 100vh;

  @include tablet-up {
    width: 100%;
    margin-left: 64px;
    margin-right: 64px;
    min-height: 885px;
  }
}

.listTitle {
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  color: #000006;
  margin: 0 16px 30px;
}
