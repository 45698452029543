@import '../../scss/mixins.scss';

.container {
  position: absolute;
  display: flex;
  width: 288px;
  height: calc(100vh - 300px - 25px - 72px);
  align-items: center;

  @include landscape {
    position: relative;
    height: auto;
    padding-top: 16px;
  }

  @include tablet-up {
    width: 642px;
    height: calc(100vh - 404px - 25px - 120px);

    @include landscape {
      position: relative;
      height: auto;
      padding-top: 16px;
    }
  }

  @include desktop-up {
    position: relative;
    width: 30%;
    margin-right: 8.3333%;
    height: unset;
  }
}

.content {
  display: flex;
  align-items: flex-start;
  width: inherit;
  padding-top: 0;
  will-change: transform;
  transform-origin: top right;

  @include tablet-up {
    padding-top: 80px;
  }

  @include desktop-up {
    flex-direction: column;
    padding-top: unset;
    position: fixed;
    top: 0;
    bottom: 118px;
    justify-content: space-evenly;
  }
}

.messageContainer {
  will-change: transform;
  transform-origin: top right;
}

.detailsContainer {
  will-change: transform;
}

.quotes {
  display: none;
  will-change: transform;
  transform-origin: center left;

  @include desktop-up {
    display: block;
    padding-bottom: 48px;
  }
}

.message {
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 32px;
  color: #000006;
  width: 288px;

  @include tablet-up {
    font-size: 32px;
    line-height: 48px;
    width: 642px;
  }

  @include desktop-up {
    width: 100%;
  }
}

.author {
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 28px;
  padding-top: 16px;
  background-image: linear-gradient(#ff4c00, #ff0000);
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  will-change: transform;
  transform-origin: center left;

  @include tablet-up {
    font-size: 16px;
    padding-top: 24px;
  }

  @include desktop-up {
    padding-top: 40px;
  }
}

.details {
  display: flex;
  flex-direction: row;
  padding-top: 64px;

  @include tablet-up {
    padding-top: 48px;
  }

  @include desktop-up {
    flex-direction: column;
    padding-top: unset;
  }
}

.shared {
  padding-right: 56px;

  @include desktop-up {
    padding-right: unset;
    padding-bottom: 24px;
  }
}

.detailsHeader {
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 28px;
  color: #000006;
  opacity: 0.5;
  will-change: transform;
  transform-origin: center left;
  display: none;

  @include desktop-up {
    display: block;
  }
}

.detailsBody {
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  color: #1f0902;
  will-change: transform;
  transform-origin: top left;
  display: none;

  @include desktop-up {
    display: block;
  }
}

.scrollHint {
  position: fixed;
  bottom: 0;
  display: none;
  will-change: opacity;

  @include desktop-up {
    display: block;
  }
}

.scrollHintText {
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 28px;
  color: #bcbcbe;
}

.scrollHintLine {
  height: 90px;
  width: 1px;
  background-color: #7e7e81;
  opacity: 0.5;
}
